<template>
  <!--  <img alt="Vue logo" src="./assets/logo.png">-->
  <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <OttoHzys></OttoHzys>
</template>

<script>
import OttoHzys from "@/components/OttoHzys"

export default {
  name: 'App',
  components: {
    OttoHzys
  }
}
</script>

<style>
</style>
